@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(//db.onlinewebfonts.com/c/e5c490a944bc2b2746f0c851501e46c7?family=AvenirNextW10-Bold);
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	background-color: #000000;
}

.container {
}

/* Custom styles */
/* Navbar */
.nav_item {
	position: relative;
	background: transparent;
	cursor: pointer;
	/* border-image: linear-gradient(#0360f8, #6afa5d) 30; */
	border: white;
	border-width: 1px;
	border-style: solid;
	transform: skewX(-20deg);
}

.nav_item:hover {
	background: linear-gradient(
		135deg,
		rgba(3, 96, 248, 1) 10%,
		rgba(106, 250, 93, 1) 100%
	);
}

.hero_section_title {
	background: linear-gradient(
		135deg,
		rgba(3, 96, 248, 1) 10%,
		rgba(106, 250, 93, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.explore_btn {
	background: linear-gradient(
		135deg,
		rgba(3, 96, 248, 1) 10%,
		rgba(106, 250, 93, 1) 100%
	);
	transform: skewX(-20deg);
	cursor: pointer;
}
.explore_btn p {
	transform: skewX(20deg);
}

.play_btn {
	cursor: pointer;
	border-image: linear-gradient(#0360f8, #6afa5d) 30;
	border-width: 1px;
	border-style: solid;
	transform: skewX(-20deg);
}

.play_btn p {
	-webkit-text-fill-color: linear-gradient(
		135deg,
		rgba(3, 96, 248, 1) 10%,
		rgba(106, 250, 93, 1) 100%
	);
	-webkit-text-stroke-color: white;
	-webkit-text-stroke-width: 1px;
	transform: skewX(20deg);
}

.about_section_play_btn {
	cursor: pointer;
	border-image: linear-gradient(#0360f8, #6afa5d) 30;
	border-width: 1px;
	border-style: solid;
	transform: skewX(-20deg);
}

.about_section_play_btn p {
	transform: skewX(20deg);
	background: linear-gradient(
		135deg,
		rgba(3, 96, 248, 1) 10%,
		rgba(106, 250, 93, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.glassmorphism {
	background: rgba(255, 255, 255, 0.07);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4.4px);
	-webkit-backdrop-filter: blur(4.4px);
	border: 1px solid rgba(255, 255, 255, 0.38);
}

.footer_glassmorphism {
	background: rgba(255, 255, 255, 0.07);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4.4px);
	-webkit-backdrop-filter: blur(4.4px);
	border-top: 1px solid rgba(255, 255, 255, 0.38);
	border-bottom: 1px solid rgba(255, 255, 255, 0.38);
}

.contact input[type="text"] {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	box-sizing: border-box;
	border: none;
	border-bottom: 2px solid white;
}

.newsletter_text {
	background: linear-gradient(
		135deg,
		rgba(3, 96, 248, 1) 10%,
		rgba(106, 250, 93, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.glassmorphism_card {
	background: rgba(255, 255, 255, 0.07);
	border-radius: 16px;
	backdrop-filter: blur(4.4px);
	-webkit-backdrop-filter: blur(4.4px);
	border: 1px solid rgba(255, 255, 255, 0.38);
}

.footer_bg {
	background: rgb(12, 102, 196);
	background: linear-gradient(
		27deg,
		rgba(12, 102, 196, 1) 55%,
		rgba(106, 250, 93, 1) 81%
	);
}

/* mobile menu */

.mobile_menu {
	background-color: #000000;
	height: 0;
	overflow: hidden;
	transition: height 0.3s ease-in-out;
}

.mobile_menu.show {
	height: 100vh;
	transition: height 0.3s ease-in-out;
}

.card {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	justify-content: center; /* Adjust vertical spacing */
	align-items: center;
	border-radius: 1rem;
	border-width: 1px;
	width: 100%;
	height: 100%;
	min-height: 350px; /* Set a minimum height for the card */
	background-color: #1e1e1e;
}

@media (min-width: 640px) {
	.card {
		width: 18rem;
	}
}
